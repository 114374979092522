import React, { Component } from "react"
import {
  GlobalContainer,
  Button,
  GlobalSection,
} from "../../components/GlobalStyle"
import styled, { css } from "styled-components"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import headerImage from "../../images/slider/slide-2.jpg"

import checked from "../../images/icons/checked.svg"
import Map from "../../components/Map"
import SectionContact from "../../components/sectionContact"
import RoomsGallery from "../../components/RoomsGallery"
import akwarium1 from "../../images/gallery/akwarium-1.jpg"
import akwarium2 from "../../images/gallery/akwarium-2.jpg"
import akwarium3 from "../../images/gallery/akwarium-3.jpg"
import akwarium4 from "../../images/gallery/akwarium-4.jpg"
import szkoleniowa1 from "../../images/gallery/szkoleniowa-1.jpg"
import szkoleniowa2 from "../../images/gallery/szkoleniowa-2.jpg"
import szkoleniowa3 from "../../images/gallery/szkoleniowa-3.jpg"
import szkoleniowa4 from "../../images/gallery/szkoleniowa-4.jpg"
import Helmet from "react-helmet"
import { withTranslation } from "react-i18next"

const StyledBody = styled.div`
  background-color: #f4f4f4;
`
const StyledOfferContainer = styled(GlobalContainer)`
  max-width: 1040px;
  margin-bottom: 200px;
  p {
    margin: 20px 0;
  }
`

const StyledFAQContainer = styled(GlobalContainer)`
  padding-top: 100px;
  max-width: 1040px;
  margin-bottom: 200px;
  a {
    font-weight: bold;
    color: var(--main);
  }
  p {
    margin: 20px 0;
  }
  ul {
    li {
      display: flex;
      &:before {
        content: "";
        display: block;
        height: 4px;
        width: 16px;
        background-color: var(--main);
        margin-right: 10px;
        position: relative;
        top: 15px;
      }
    }
    div {
      flex: 1;
      p {
        margin-top: 0;
      }
    }
  }
`
const StyledPageHeaderDetails = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(12, 0, 46, 0.04);
  position: relative;
  transform: translateY(-50%);
  @media (max-width: 800px) {
    flex-direction: column;
    transform: translateY(-50px);
  }
  & > div {
    flex: 1;
    text-align: center;
    padding: 30px 10px;
    font-size: 1.4em;
    line-height: 1.1em;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 20px);
      right: 0;
      display: block;
      height: 40px;
      width: 1px;
      background-color: var(--main);
      @media (max-width: 800px) {
        display: none;
      }
    }
    &:last-of-type:after {
      display: none;
    }
    span {
      text-transform: uppercase;
      font-weight: bold;
      opacity: 0.5;
      display: block;
      font-size: 0.6em;
    }
  }
`

const StyledCoworkPlansPricing = styled.div`
  ul {
    margin-left: 40px;
    li {
      list-style: none;
      position: relative;
      color: rgba(#000, 0.6);
      margin: 6px 0;
      font-size: 0.9em;
      &::before {
        content: "";
        background-image: none;
        display: inline-block;
        width: 15px;
        height: 2px;
        background-color: var(--main);
        position: absolute;
        left: -30px;
        top: 12px;
      }
    }
  }
`
const StyledCenteredBox = styled.div`
  text-align: center;
  margin: 50px 0;
  div {
    margin-bottom: 20px;
  }
`
const StyledDivider = styled.div`
  border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
  margin: 40px 0;
`
const StyledCoworkPlans = styled.div`
  display: flex;
  width: 1300px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px 0 80px;
  ${props =>
    props.wrap &&
    css`
      justify-content: center;
      & > div {
        width: 400px;
        flex: none;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    `}
  @media(max-width: 1320px) {
    width: 100%;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`
const StyledCoworkPlansItem = styled.div`
  flex: 1;
  background-color: #fff;
  padding: 20px 20px 40px 20px;
  margin: ${props => (props.active ? "0 15px" : "30px 4px")};
  position: relative;
  &:after {
    content: '';
    display: ${props => (props.active ? "block" : "none")};
    height: calc(100% + 6px);
    width: calc(100% + 6px);
    background-image: linear-gradient(45deg, #2EC796, #A49AFE );
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
  }
  @media(max-width: 1320px) {
    padding: 20px 5px;
  }
})
`
const StyledCoworkPlansHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
  span {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    strong {
      display: inline-block;
      margin-left: 4px;
      color: var(--main);
    }
  }
  i {
    font-size: 1.6em;
    font-weight: bold;
    font-style: normal;
  }
  s {
    font-size: 1.8em;
    display: block;
    line-height: 2em;
    position: relative;
    top: -20px;
    opacity: 0.6;
  }
  & > div {
    margin: 30px 0 10px;
    line-height: 1.6em;
    strong {
      font-size: 4em;
      font-weight: bold;
    }
  }
`
const StyledCoworkPlansDescription = styled.div`
  ul {
    margin-left: 40px;
    @media (max-width: 1100px) {
      width: 200px;
      margin: 0 auto;
    }
    li {
      list-style: none;
      position: relative;
      color: rgba(#000, 0.6);
      margin: 6px 0;
      font-size: 0.9em;
      @media (max-width: 1320px) {
        font-size: 14px;
      }
      &::before {
        content: "";
        display: inline-block;
        height: 16px;
        width: 16px;
        background-image: url(${checked});
        background-size: contain;
        position: absolute;
        left: -30px;
        top: 5px;
      }
    }
  }
`
const StyledHourInfo = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 0.9em;
  padding: 0 40px;
  p {
    opacity: 0.6;
    position: relative;
    top: -30px;
  }
`
const StyledCoworkPerks = styled.div`
  li {
    padding: 20px 0;
  }
  h3 {
    font-size: 1.5em;
    font-weight: 800;
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1.3em;
    }
  }
  p {
    padding-left: 52px;
    opacity: 0.7;
    margin-top: 10px;
    text-align: left;
    @media (max-width: 500px) {
      text-align: center;
      padding-left: 0;
      font-size: 0.9em;
    }
  }
`
const StyledCoworkIcon = styled.span`
  display: inline-block;
  height: 42px;
  width: 42px;
  background-size: contain;
  position: relative;
  margin-right: 10px;
  top: 12px;
  background-image: url(${props => props.icon});
  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`
const StyledPlansTabs = styled.div`
  display: flex;
  width: 500px;
  margin: 80px auto 60px;
  border-radius: 100px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1),
    inset 0 0 20px rgba(0, 0, 0, 0.05);
  @media (max-width: 700px) {
    width: auto;
    margin: 80px 10px 60px;
  }
`
const StyledPlansTabsTab = styled.div`
  flex: 1;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  @media (max-width: 700px) {
    padding: 8px;
  }
  ${props =>
    props.active &&
    css`
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      position: relative;
      &:after {
        content: "";
        display: block;
        height: 3px;
        width: 30px;
        background-color: var(--main);
        position: absolute;
        bottom: 0;
        left: calc(50% - 10px);
      }
    `}
`
const StyledPlanType = styled.div`
  position: relative;
  .select-options {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    width: 240px;
    text-align: left;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    border-radius: 4px;
    border: 2px solid #f4f4f4;
    top: 26px;
    display: none;
    margin-top: 10px;
    &:hover {
      display: block;
    }
  }
  span {
    padding: 4px 8px;
    transition: 0.2s;
    display: block;
    cursor: pointer;
    &:hover {
      background-color: #f4f4f4;
    }
  }
  .selected-option {
    display: inline-block;
    padding: 2px 40px 2px 12px;
    border-radius: 40px;
    font-size: 0.9em;
    background-color: #f4f4f4;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    margin-bottom: 40px;
    &.only {
      padding-right: 12px;
      cursor: inherit;
      &:after {
        display: none;
      }
    }
    &:hover {
      border-bottom: 10px solid #fff;
      margin-bottom: 30px;
      & + .select-options {
        display: block;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      right: 15px;
      height: 6px;
      width: 6px;
      border-top: 2px solid black;
      border-right: 2px solid black;
      transform: rotate(135deg);
    }
  }
`
const StyledCoworkPlanPrice = styled.div`
  position: relative;
  left: 10px;
  margin-top: 50px !important;
`
const StyledCoworkPlanUnavailable = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(192, 192, 192, 0.4);
  span {
    position: absolute;
    display: block;
    width: 200%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    padding: 15px 10px;
    background-color: #c0c0c0;
  }
`

export const photosSzkoleniowa = [
  {
    src: szkoleniowa1,
    width: 4,
    height: 3,
  },
  {
    src: szkoleniowa2,
    width: 4,
    height: 3,
  },
  {
    src: szkoleniowa3,
    width: 4,
    height: 3,
  },
  {
    src: szkoleniowa4,
    width: 4,
    height: 3,
  },
]
export const photosAkwarium = [
  {
    src: akwarium1,
    width: 4,
    height: 3,
  },
  {
    src: akwarium2,
    width: 4,
    height: 3,
  },
  {
    src: akwarium3,
    width: 4,
    height: 3,
  },
  {
    src: akwarium4,
    width: 4,
    height: 3,
  },
]
class RoomsOfferPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      plan: "1 Godzina",
      type: "sala-szkoleniowa",
      desk: "biurko dedykowane",
    }
  }

  render() {
    return (
      <StyledBody>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "Gdzie znajdę sale szkoleniowe w trójmieście?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Jeśli szukasz sal szkoleniowych w Trójmieście, znajdziesz je w Leśnahub na terenie Sopotu. Sale szkoleniowe szeroki zakres cen i rozmiarów, aby dopasować się do każdego budżetu i potrzeb. Dopasuj sale do odpowiedniego spotkania biznesowego."
                  }
                },{
                  "@type": "Question",
                  "name": "Ile kosztuje wynajem sali szkoleniowej?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Koszt wynajęcia sali szkoleniowej zależy od wielkości sali, na ile godzin jej potrzebujesz. Leśna Hub stwarza możliwość zorganizowania szkoleń i konferencji w samym centrum Trójmiasta. Dzięki doskonałej lokalizacji dojazd staje się bezproblemowy praktycznie każdym środkiem transportu. Ceny zaczynają się już od 69 PLN/H"
                  }
                }]
              }
          `}
          </script>
        </Helmet>
        <SEO
          title="Sale szkoleniowe"
          description="Nasza przestrzeń oferuje dużą salę konferencyjną idealną dla cyklicznych meetupoów i konferancji, oraz mniejsze sale wideokonferencyjne na spotkania z Twoimi klientami"
        />
        <PageHeader image={headerImage}>
          <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">
            {this.props.t("offerTraingroom.header")}
          </h1>
        </PageHeader>
        <StyledOfferContainer>
          <StyledPageHeaderDetails
            data-sal="fade"
            data-sal-delay="500"
            data-sal-duration="400"
          >
            <div
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="400"
            >
              <span>{this.props.t("offerTraingroom.rooms")}</span>3
            </div>
            <div
              data-sal="slide-up"
              data-sal-delay="650"
              data-sal-duration="400"
            >
              <span>{this.props.t("common.area")}</span>
              92m2
            </div>
            <div
              data-sal="slide-up"
              data-sal-delay="700"
              data-sal-duration="400"
            >
              <span>{this.props.t("common.location")}</span>
              Sopot
            </div>
            <div
              data-sal="slide-up"
              data-sal-delay="750"
              data-sal-duration="400"
            >
              <span>{this.props.t("offerTraingroom.capacity")}</span>
              60 {this.props.t("offerTraingroom.people")}
            </div>
          </StyledPageHeaderDetails>

          <section>
            <div>
              <p>
                {this.props.t("offerTraingroom.text1")}
                <br />
                <br />
                {this.props.t("offerTraingroom.text2")}
              </p>
              <div> {this.props.t("offerTraingroom.text3")}</div>

              <section>
                <StyledPlansTabs>
                  {/* <StyledPlansTabsTab
                    active={this.state.type === "sale-wideokonferencyjne"}
                    onClick={() => this.setState({ type: "sale-wideokonferencyjne" })}>
                    Sale Wideokonferencyjne
                  </StyledPlansTabsTab> */}
                  <StyledPlansTabsTab
                    active={this.state.type === "sala-szkoleniowa"}
                    onClick={() => this.setState({ type: "sala-szkoleniowa" })}
                  >
                    {this.props.t("offerTraingroom.packageTitle")}
                  </StyledPlansTabsTab>
                </StyledPlansTabs>

                {/* {this.state.type == "sale-wideokonferencyjne" &&
                <StyledCoworkPlans wrap>
                  <StyledCoworkPlansItem>
                  <StyledCoworkPlanUnavailable>
                      <span>Aktualnie niedostępne</span>
                    </StyledCoworkPlanUnavailable>
                    <StyledCoworkPlansHeader>
                      <span>Sala Dolna</span>
                      <StyledCoworkPlanPrice>
                        <strong>
                          { this.state.plan === "1 Godzina" && 59}
                          { this.state.plan === "3 Godziny" && 169}
                          { this.state.plan === "1 Dzień" && 399}
                          { this.state.plan === "3 Dni" && 1089}
                          { this.state.plan === "10 Godzin (abonament)" && 550}
                          { this.state.plan === "20 Godzin (abonament)" && 1000}
                          { this.state.plan === "30 Godzin (abonament)" && 1350}
                        </strong>

                        <i>zł</i>
                      </StyledCoworkPlanPrice>
                      <StyledPlanType>
                        <div
                          className="selected-option">{this.state.plan}</div>
                        <div className="select-options">
                          <span onClick={() => this.setState({ plan: "1 Godzina" })}>1 Godzina</span>
                          <span onClick={() => this.setState({ plan: "3 Godziny" })}>3 Godziny</span>
                          <span onClick={() => this.setState({ plan: "1 Dzień" })}>1 Dzień</span>
                          <span onClick={() => this.setState({ plan: "3 Dni" })}>3 Dni</span>
                          <span onClick={() => this.setState({ plan: "10 Godzin (abonament)" })}>10 Godzin (abonament)</span>
                          <span onClick={() => this.setState({ plan: "20 Godzin (abonament)" })}>20 Godzin (abonament)</span>
                          <span onClick={() => this.setState({ plan: "30 Godzin (abonament)" })}>30 Godzin (abonament)</span>
                        </div>
                      </StyledPlanType>
                    </StyledCoworkPlansHeader>
                    <StyledCoworkPlansDescription>
                      <ul>
                        <li>dostęp do sprzętu biurowego</li>
                        <li>dostęp do szybkiego WiFi</li>
                        <li>przerwa kawowa 8 zł/netto osoba</li>
                      </ul>
                    </StyledCoworkPlansDescription>

                  </StyledCoworkPlansItem>
                  <StyledCoworkPlansItem>
                    <StyledCoworkPlanUnavailable>
                      <span>Aktualnie niedostępne</span>
                    </StyledCoworkPlanUnavailable>
                    <StyledCoworkPlansHeader>
                      <span>Sala Akwarium</span>
                      {this.state.plan == "płatność miesięczna" &&
                      <StyledCoworkPlanPrice>
                        <strong>749</strong> <i>zł</i>
                      </StyledCoworkPlanPrice>
                      }
                      {this.state.plan !== "płatność miesięczna" &&
                      <StyledCoworkPlanPrice>
                        <strong>
                          { this.state.plan === "1 Godzina" && 69}
                          { this.state.plan === "3 Godziny" && 189}
                          { this.state.plan === "1 Dzień" && 499}
                          { this.state.plan === "3 Dni" && 1349}
                          { this.state.plan === "10 Godzin (abonament)" && 650}
                          { this.state.plan === "20 Godzin (abonament)" && 1200}
                          { this.state.plan === "30 Godzin (abonament)" && 1650}
                        </strong>
                        <i>zł</i>
                      </StyledCoworkPlanPrice>
                      }
                      <StyledPlanType>
                        <div
                          className="selected-option">{this.state.plan}</div>
                        <div className="select-options">
                          <span onClick={() => this.setState({ plan: "1 Godzina" })}>1 Godzina</span>
                          <span onClick={() => this.setState({ plan: "3 Godziny" })}>3 Godziny</span>
                          <span onClick={() => this.setState({ plan: "1 Dzień" })}>1 Dzień</span>
                          <span onClick={() => this.setState({ plan: "3 Dni" })}>3 Dni</span>
                          <span onClick={() => this.setState({ plan: "10 Godzin (abonament)" })}>10 Godzin (abonament)</span>
                          <span onClick={() => this.setState({ plan: "20 Godzin (abonament)" })}>20 Godzin (abonament)</span>
                          <span onClick={() => this.setState({ plan: "30 Godzin (abonament)" })}>30 Godzin (abonament)</span>
                        </div>
                      </StyledPlanType>
                    </StyledCoworkPlansHeader>

                    <StyledCoworkPlansDescription>
                      <ul>
                        <li>dostęp do sprzętu biurowego</li>
                        <li>dostęp do szybkiego WiFi</li>
                        <li>przerwa kawowa 8 zł/netto osoba</li>
                      </ul>
                    </StyledCoworkPlansDescription>
                  </StyledCoworkPlansItem>




                </StyledCoworkPlans>
                } */}

                {this.state.type == "sala-szkoleniowa" && (
                  <StyledCoworkPlans>
                    <StyledCoworkPlansItem>
                      <StyledCoworkPlansHeader>
                        <span>
                          {" "}
                          {this.props.t("offerTraingroom.package1Time")}
                        </span>
                        <StyledCoworkPlanPrice>
                          <strong>349</strong>
                          <i>zł</i>
                        </StyledCoworkPlanPrice>
                      </StyledCoworkPlansHeader>
                      <StyledCoworkPlansDescription>
                        <ul>
                          <li>
                            {this.props.t("offerTraingroom.packageText1")}
                          </li>
                          <li>
                            {this.props.t("offerTraingroom.packageText2")}
                          </li>
                          <li>
                            {this.props.t("offerTraingroom.packageText3")}
                          </li>
                          <li>
                            {this.props.t("offerTraingroom.packageText4")}
                          </li>
                        </ul>
                      </StyledCoworkPlansDescription>
                    </StyledCoworkPlansItem>
                    <StyledCoworkPlansItem active>
                      <StyledCoworkPlansHeader>
                        <span>
                          {this.props.t("offerTraingroom.package2Time")}
                          <strong>-100 zł</strong>
                        </span>
                        <StyledCoworkPlanPrice>
                          <strong>799</strong> <i>zł</i>
                        </StyledCoworkPlanPrice>
                      </StyledCoworkPlansHeader>
                      <StyledCoworkPlansDescription>
                        <ul>
                          <li>
                            {this.props.t("offerTraingroom.packageText1")}
                          </li>
                          <li>
                            {this.props.t("offerTraingroom.packageText2")}
                          </li>
                          <li>
                            {this.props.t("offerTraingroom.packageText3")}
                          </li>
                          <li>
                            {this.props.t("offerTraingroom.packageText4")}
                          </li>
                        </ul>
                      </StyledCoworkPlansDescription>
                    </StyledCoworkPlansItem>

                    <StyledCoworkPlansItem>
                      <StyledCoworkPlansHeader>
                        <span>
                          {this.props.t("offerTraingroom.package3Time")}
                        </span>
                        <StyledCoworkPlanPrice>
                          <strong>1699</strong> <i>zł</i>
                        </StyledCoworkPlanPrice>
                      </StyledCoworkPlansHeader>
                      <StyledCoworkPlansDescription>
                        <ul>
                          <li>
                            {this.props.t("offerTraingroom.packageText1")}
                          </li>
                          <li>
                            {this.props.t("offerTraingroom.packageText2")}
                          </li>
                          <li>
                            {this.props.t("offerTraingroom.packageText3")}
                          </li>
                          <li>
                            {this.props.t("offerTraingroom.packageText4")}
                          </li>
                        </ul>
                      </StyledCoworkPlansDescription>
                    </StyledCoworkPlansItem>
                  </StyledCoworkPlans>
                )}

                {/* <StyledHourInfo>
                  <p classNamne="brutto">Podane ceny są cenami brutto</p>
                </StyledHourInfo> */}
                <StyledCenteredBox>
                  <div>{this.props.t("offerTraingroom.form")}</div>
                  <Button to="/rezerwacja">
                    {this.props.t("common.reservation")}
                  </Button>
                </StyledCenteredBox>
              </section>

              <StyledDivider />
              <h3>{this.props.t("offerTraingroom.descTitle")}</h3>
              <p>{this.props.t("offerTraingroom.descText")}</p>
              <section>
                <div></div>
              </section>

              <section>
                <div>
                  {this.props.t("offerTraingroom.descSubtext")}
                  <br />
                </div>
                <StyledCoworkPlansDescription>
                  <ul>
                    <li>{this.props.t("offerTraingroom.descPerks1")}</li>
                    <li>{this.props.t("offerTraingroom.descPerks2")}</li>
                    <li>{this.props.t("offerTraingroom.descPerks3")}</li>
                    <li>{this.props.t("offerTraingroom.descPerks4")}</li>
                  </ul>
                </StyledCoworkPlansDescription>
              </section>
              <br />
              <RoomsGallery photos={photosSzkoleniowa} />
              {/* <StyledDivider/> */}
              {/* <h3>Sala wideokonferencyjna</h3>
              <p>
                Sala wideokonferencyjna doskonale sprawdzi się na spotkanie biznesowe, zebrania, rekrutacje lub
                niewielkie
                szkolenia. W sali znajdują się tablice suchościeralne oraz telewizor. Pozwala to na
                przeprowadzenie wszelkiego rodzaju spotkań w bardzo komfortowych warunkach.
              </p>
              <section>
                <div>
                  Minimalny czas wynajmu sali wideokonferencyjnej wynosi <strong>1 godzinę</strong>.
                  <br/>
                </div>
                <StyledCoworkPlansDescription>
                  <ul>
                    <li>dostęp do wi-fi</li>
                    <li>telewizor</li>
                    <li>tablica suchościeralna</li>
                    <li>mazaki</li>
                  </ul>
                </StyledCoworkPlansDescription>
              </section>
              <br/>
              <RoomsGallery photos={photosAkwarium}/> */}
            </div>
          </section>
        </StyledOfferContainer>
        <Map />

        <GlobalSection bg="#F4F4F4">
          <StyledFAQContainer>
            <h1>{this.props.t("offerTraingroom.roomsGdaTitle")}</h1>

            <p>{this.props.t("offerTraingroom.roomsGdaText1")}</p>

            <p>{this.props.t("offerTraingroom.roomsGdaText2")}</p>

            <p>
              {this.props.t("offerTraingroom.roomsGdaText3")}{" "}
              <a href="/oferta/wirtualne-biuro">
                {this.props.t("offerTraingroom.link1")}
              </a>{" "}
              {this.props.t("offerTraingroom.link")}{" "}
              <a href="/oferta/coworking">
                {this.props.t("offerTraingroom.link2")}
              </a>
              .
            </p>
          </StyledFAQContainer>
        </GlobalSection>

        <SectionContact />
      </StyledBody>
    )
  }
}

export default withTranslation()(RoomsOfferPage)
